import { createAction, props } from '@ngrx/store';
import { BillingStoreInterface } from '../models/billing-store.interface';

export enum BillingActions {
  GetBillingInfo = '[Billing] Get Billing Info',
  SetBillingInfo = '[Billing] Set Billing Info',
  SetAutoCharge = '[Billing] Set Auto Charge',
  SetVipStatus = '[Billing] Set Vip Status',
}

export const getBillingInfo = createAction(BillingActions.GetBillingInfo);

export const setBillingInfo = createAction(
  BillingActions.SetBillingInfo,
  props<BillingStoreInterface>(),
);

export const setAutoCharge = createAction(
  BillingActions.SetAutoCharge,
  props<{ autoCharge: boolean }>(),
);

export const setVipStatus = createAction(BillingActions.SetVipStatus, props<{ status: boolean }>());
