import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppStateInterface } from '../../store/state/app.state';
import { uuidSelector } from '../../store/selectors/profile.selectors';
import { combineLatest, Observable } from 'rxjs';
import { getBillingInfo, setAutoCharge, setVipStatus } from '../../store/actions/billing.actions';
import { getProfile } from '../../store/actions/profile.actions';
import { getSubscriptionInfo } from '../../store/actions/subscription.actions';
import { processingUuidSelector } from '../../store/selectors/billing.selectors';
import { environment } from '../../../environments/environment';

export interface SubscriptionResponseInterface {
  status: string;
  data: {
    web2app: {
      cancellation_flow: {
        regular_add_solutions: boolean;
        regular_discount: boolean;
        trial_add_solutions: boolean;
        trial_discount: boolean;
      };
      subscription: {
        balance_solution: number;
        days_left: number;
        product_name: string;
        product_price: string;
        start_ms: number;
        stop_ms: number;
      };
    };
  };
}

export interface BillingResponseInterface {
  data: {
    balance: {
      solutions: number;
      active_solutions: number;
      apple: {
        subscription: number;
        onetime: 0;
      };
      web2app: {
        subscription: number;
        onetime: 0;
      };
      android: {
        subscription: number;
        onetime: 0;
      };
    };
    subscription: {
      global: {
        is_active: boolean;
        pay_status: boolean;
        has_priority: boolean;
      };
      apple: {
        is_active: boolean;
        expired_at: number;
        product_id: string;
        is_trial: boolean;
        priority: {
          is_active: boolean;
          expired_at: string | null;
          product_id: string | null;
        };
      };
      web2app: {
        cancel_at: number | null;
        is_active: boolean;
        expired_at: number;
        product_id: string;
        is_trial: boolean;
        daily_solution: number;
        priority: {
          is_active: boolean;
          expired_at: string | null;
          product_id: string | null;
        };
        next_daily_edge_ms: number | null;
      };
      android: {
        is_active: boolean;
        expired_at: string | null;
        product_id: string | null;
        is_trial: boolean;
      };
    };
    payment_config: {
      global: Array<any>;
      apple: {
        is_unlim: boolean;
      };
      web2app: {
        auto_charge: boolean;
        account_uuid: string;
      };
      android: [];
    };
    sandbox: {
      apple: {
        is_trial: boolean;
        apple_transaction_id: string;
        apple_subscription_expired_at: string;
        product_id: string;
        date: string;
      };
    };
  };
  status: string;
}

/**
 * @interface GetSomeBunRequestInterface
 * @param {string} actionName - ['add_solutions', 'discount', 'cancel']
 * @param {string} solutionsCount - used when actionName='add_solutions' [5,10]
 * <ul>
 *   <li>
 *     add_solutions - when you need to top-up user balance
 *   </li>
 *   <li>
 *     discount - when you need to give some discount for user
 *   </li>
 *   <li>
 *     cancel - when you need to cancel user subscription right-now
 *   </li>
 * </ul>
 */
interface GetSomeBunRequestInterface {
  actionName: string;
  solutionsCount?: number;
}

@Injectable({
  providedIn: 'any',
})
export class BillingService {
  private uuid: string = '';

  private processingUuid: string = '';

  constructor(private api: ApiService, private store: Store<AppStateInterface>) {
    combineLatest([
      this.store.select(uuidSelector),
      this.store.select(processingUuidSelector),
    ]).subscribe(([uuid, processingUuid]) => {
      this.uuid = uuid;
      this.processingUuid = processingUuid;
    });
  }

  getUserBillingStatus(): Observable<BillingResponseInterface> {
    return this.api.post(
      '/api/billing/show/v2',
      {},
      {
        Authorization: this.uuid,
      },
    );
  }

  getSubscriptionInfo(): Observable<SubscriptionResponseInterface> {
    return this.api.post(
      '/api/user/profile/show/subscription',
      {},
      {
        Authorization: this.uuid,
      },
    );
  }

  purchaseSolution(productUuid: any) {
    return (
      this.api
        // '/api/billing/web2app/purchase',
        // .post
        .postWeb2app(
          '/paymentflow/upsell/store',
          {
            product_uuid: productUuid,
            // sandbox: !environment.production,
            redirect_page: environment.appDomain,
            account_uuid: this.processingUuid,
          },
          // {
          //   Authorization: this.uuid,
          // },
        )
        .pipe(
          tap((response: any) => {
            if (response.status === 'success') {
              this.store.dispatch(getBillingInfo());
            }
            if (response.status === 'required_action') {
              window.location.href = response.data.redirect_page;
            }
          }),
        )
    );
  }

  autoChargeUpdate(newState: boolean) {
    return this.api
      .post(
        '/api/billing/autocharge',
        {
          status: newState.toString(),
        },
        {
          Authorization: this.uuid,
        },
      )
      .pipe(
        tap((response: any) => {
          if (response.status === 'success') {
            this.store.dispatch(
              setAutoCharge({
                autoCharge: newState,
              }),
            );
          }
        }),
      );
  }

  purchaseVipSubscription(productUuid: any) {
    return this.api
      .post(
        '/api/billing/web2app/purchase/priority',
        {
          product_uuid: productUuid,
          sandbox: !environment.production,
        },
        {
          Authorization: this.uuid,
        },
      )
      .pipe(
        tap((response: any) => {
          if (response.status === 'success') {
            this.store.dispatch(getBillingInfo());
          }
        }),
      );
  }

  /**
   * Mathod used on cancellation flow to choose some bun
   * @param {GetSomeBunRequestInterface} data - request object
   */
  getSomeBun(data: GetSomeBunRequestInterface) {
    return this.api
      .post(
        '/api/billing/add/support',
        {
          action_name: data.actionName,
          solutions_count: data.solutionsCount,
        },
        {
          Authorization: this.uuid,
        },
      )
      .pipe(
        tap((response) => {
          if (response.status === 'success') {
            this.store.dispatch(getProfile());
            this.store.dispatch(getBillingInfo());
            this.store.dispatch(getSubscriptionInfo());
          }
        }),
      );
  }

  /**
   * Method for Ab-test fast-subscription-upgrade
   * from trial to regular
   * @param uuid - regular product uuid
   */
  fastSubUpgrade(uuid: string) {
    return this.api
      .postWeb2app('/paymentflow/upsell/store', {
        account_uuid: this.processingUuid,
        product_uuid: uuid,
        redirect_page: environment.appDomain,
      })
      .pipe(
        tap((response: any) => {
          if (response.status === 'success') {
            this.store.dispatch(setVipStatus({ status: true }));
            window.location.href = response.data.redirect_page;
          }
          if (response.status === 'required_action') {
            window.location.href = response.data.redirect_page;
          }
        }),
      );
  }
}
